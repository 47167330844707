import React from "react";
import Layout from "../components/layout";
import { FormattedMessage } from "react-intl";

export default function LoadingCirrus() {
  return (
    <Layout>
      <div className="container">
        <h1 className="title is-1 mb-6 has-text-centered ">
          <FormattedMessage id="loginGreeting" />
        </h1>
        <h1 className="title is-3 pb-6 has-text-centered ">
          <FormattedMessage id="loginSubtitle" />
        </h1>
        <div className="py-6 is-flex is-justify-content-center">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
